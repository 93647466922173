import { ApiBase } from '../API';
import env from '@beam-australia/react-env';

export interface IWorkflowApi {
  retryBackgroundJob: (jobId: string) => Promise<any>;
  cancelBackgroundJob: (jobId: string) => Promise<any>;
}

const basePath = env('BASE_URL');

class WorkflowApi extends ApiBase implements IWorkflowApi {
  constructor() {
    super(basePath);
  }

  public retryBackgroundJob = async (jobId: string): Promise<any> => {
    return this.instance.put(`fsoworkflowtasks/api/BackgroundJobs/RetryJob/${jobId}`);
  };

  public cancelBackgroundJob = async (jobId: string): Promise<any> => {
    return this.instance.put(`fsoworkflowtasks/api/BackgroundJobs/CancelJob/${jobId}`);
  };
}

export default WorkflowApi;
